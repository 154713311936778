import React, { Component } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUsers } from '@fortawesome/free-solid-svg-icons';
import Loader from '../Loader';
import styles from './styles.module.css';

type ModalContainerProps = {
  modalIsOpen: boolean,
  toggleModal: Function,
  painterId: string,
  teamName: string,
  teamId: string,
  email: string,
  isLoading: boolean,
  CTI: Record<string, string>,
  bindleId: string,
  awsAccountId: string,
  weblabs?: string[]
  cloudWatchDashboards: Record<string, string>,
  widgetGroupId: string,
  pageType: string,
  subPageType: string
};

class ModalContainer extends Component<ModalContainerProps> {
  handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.target instanceof HTMLElement && event.target?.dataset?.tag === 'modalContainer') {
      event.preventDefault();
      this.props.toggleModal();
    }
  }

  renderWeblabs = (weblabs: string[]) => weblabs.map((weblab, index) => {
    const isNotLast = index !== weblabs.length - 1;
    return (
      <React.Fragment key={weblab}>
        <a
          href={`https://weblab.amazon.com/wl/${weblab}`}
          className={styles.metaDataLink}
          target="_blank"
          rel="noopener noreferrer"
        >{weblab}</a>
        {isNotLast && ', '}
      </React.Fragment>
    );
  });

  renderCloudWatchDashboards = (cloudWatchDashboards: Record<string, string>) => {
    const dashboards = Object
      .keys(cloudWatchDashboards)
      .map((key) => ({ region: key, link: cloudWatchDashboards[key] }));
    return dashboards.map((dashboard, index) => {
      const { region, link } = dashboard;
      const isNotLast = index !== dashboards.length - 1;
      return (
        <React.Fragment key={region}>
          <a
            href={link}
            className={styles.metaDataLink}
            target="_blank"
            rel="noopener noreferrer"
          >{region}</a>
          {isNotLast && ', '}
        </React.Fragment>
      )
    });
  }

  renderCardDataContent = () => {
    const {
      painterId,
      email,
      teamName,
      teamId,
      CTI,
      bindleId,
      awsAccountId,
      weblabs,
      cloudWatchDashboards,
      widgetGroupId,
      pageType,
      subPageType
    } = this.props;
    return (
      <div className={styles.modalContent}>
        <div className={styles.cardNameText}>{painterId}</div>
        <div className={styles.cardMetaDataContainer}>
          <div className={styles.metaDataRow}>
            <div className={styles.metaDataHeader}>Team:</div>
            <div className={styles.metaDataText}>{teamName}</div>
            <a
              href={`mailto:${email}`}
              target="_blank"
              className={styles.teamMailLink}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
            <a
              href={`https://permissions.amazon.com/a/team/${teamId}`}
              target="_blank"
              className={styles.teamPageLink}
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faUsers} />
            </a>
          </div>
          <div className={styles.metaDataRow}>
            <div className={styles.metaDataHeader}>CTI:</div>
            <div className={styles.metaDataText}>
              <div className={styles.ctiContainer}>
                <div className={styles.ctiRow}>
                  {CTI.category}
                </div>
                <div className={styles.ctiRow}>
                  {CTI.type}
                </div>
                <div className={styles.ctiRow}>
                  {CTI.item}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.metaDataRow}>
            <div className={styles.metaDataHeader}>Bindle:</div>
            <div className={styles.metaDataText}>{bindleId}</div>
          </div>
          <div className={styles.metaDataRow}>
            <div className={styles.metaDataHeader}>AWS Account:</div>
            <div className={styles.metaDataText}>{awsAccountId}</div>
          </div>
          {
            weblabs &&
            (
              <div className={styles.metaDataRow}>
                <div className={styles.metaDataHeader}>Weblabs:</div>
                <div className={styles.metaDataText}>{this.renderWeblabs(weblabs)}</div>
              </div>
            )
          }
          <div className={styles.metaDataRow}>
            <div className={styles.metaDataHeader}>Cloudwatch Dashboards:</div>
            <div className={styles.metaDataText}>{this.renderCloudWatchDashboards(cloudWatchDashboards)}</div>
          </div>
          {
            widgetGroupId &&
            (
              <div className={styles.metaDataRow}>
                <div className={styles.metaDataHeader}>Widget Group Id:</div>
                <div className={styles.metaDataText}>{widgetGroupId}</div>
              </div>
            )
          }
          {
            pageType &&
            (
              <div className={styles.metaDataRow}>
                <div className={styles.metaDataHeader}>Page Type:</div>
                <div className={styles.metaDataText}>{pageType}</div>
              </div>
            )
          }
          {
            subPageType &&
            (
              <div className={styles.metaDataRow}>
                <div className={styles.metaDataHeader}>Sub-Page Type:</div>
                <div className={styles.metaDataText}>{subPageType}</div>
              </div>
            )
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      modalIsOpen,
      isLoading,
    } = this.props;
    const containerClassNames = classnames({
      [styles.container]: true,
      [styles.containerVisible]: modalIsOpen
    });
    const innerContainerClassNames = classnames({
      [styles.innerContainer]: true,
      [styles.innerContainerVisible]: modalIsOpen
    });
    return (
      <div data-tag="modalContainer" className={containerClassNames} onClick={this.handleModalClick}>
        <div className={innerContainerClassNames}>
          <div className={styles.modalNavContainer}>
            <div className={`${styles.tab} ${styles.tabSelected}`}>Card Information</div>
          </div>
          { isLoading && <Loader /> }
          { !isLoading && this.renderCardDataContent() }
        </div>
      </div>
    );
  }
}

export default ModalContainer;