import React, { Component } from 'react';
import HoverContainer from '../../components/HoverContainer';
import ModalContainer from '../../components/ModalContainer';
import { CardOnboardingData, getCardDataFromDb } from '../../utils/card-onboarding-db-access';
import styles from './styles.module.css';

interface CardDeployMetadata {
  cardId: string,
  cardVersion: string,
  endpoint: string,
  environmentType: string,
  weblabs: string[]
}

export interface AppProps {
  painterId: string,
  widgetGroupId: string,
  pageType: string,
  subPageType: string,
  cardDeployMetadata?: CardDeployMetadata
}

interface AppState extends CardOnboardingData {
  modalIsOpen: boolean,
  isLoading: boolean
}

class App extends Component<AppProps, AppState> {
  state = {
    modalIsOpen: false,
    isLoading: true,
    teamName: '',
    emailId: '',
    teamId: '',
    cti: '',
    bindleId: '',
    pipelineId: '',
    awsAccountId: '',
    dashboard: {
      EU_PROD: '',
      FE_PROD: '',
      NA_PROD: ''
    }
  };

  toggleModal = () => this.setState(prevState => ({ modalIsOpen: !prevState.modalIsOpen }));
  processCTI = (CTIRaw: string) => {
    const [category, type, item, assignedGroup] = CTIRaw.split(':');
    return {
      category,
      type,
      item,
      assignedGroup
    };
  }

  async componentDidMount() {
    const cardDataFromDb = await getCardDataFromDb(this.props.cardDeployMetadata?.cardId);
    this.setState({ ...cardDataFromDb, isLoading: false });

  }

  render() {
    const {
      modalIsOpen,
      teamName,
      emailId: email,
      teamId,
      cti: CTIRaw,
      bindleId,
      awsAccountId,
      dashboard: cloudWatchDashboards,
      isLoading
    } = this.state;
    const { painterId, widgetGroupId, pageType, subPageType } = this.props;
    const CTI = this.processCTI(CTIRaw);

    return (
      <>
        <div className={styles.container}>
          <HoverContainer
            {...{ painterId }}
            toggleModal={this.toggleModal}
          />
        </div>
        <ModalContainer
          {...{
            painterId,
            teamName,
            CTI,
            bindleId,
            awsAccountId,
            email,
            teamId,
            weblabs: this.props.cardDeployMetadata?.weblabs,
            cloudWatchDashboards,
            widgetGroupId,
            pageType,
            subPageType,
            isLoading
          }}
          modalIsOpen={modalIsOpen}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}

export default App;