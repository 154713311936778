import React from 'react';
import ReactDOM from 'react-dom';
import App, { AppProps } from './containers/App';

/**
 * Render live debug HTML code inside ld-root elements
 *
 */
function renderLdRoot() {
  const ldRoots = document.querySelectorAll('.ld-root');
  ldRoots.forEach(ldRoot => {
    let dataFromDram: AppProps = { painterId: 'N/A', widgetGroupId: 'N/A', pageType: 'N/A', subPageType: 'N/A' };
    if (ldRoot.getAttribute('data')) {
      const dataFromDramString = ldRoot.getAttribute('data')!.trim();
      try {
        dataFromDram = JSON.parse(dataFromDramString);
      } catch (err) {
        console.error("Failed to parse the attributes.data of the ldRoot element under painter element: ", ldRoot.parentElement);
        console.error('Invalid JSON string: ', dataFromDramString);
      }
    } else {
      console.error("No 'attribute: data' found in the ldRoot element under painter element", ldRoot.parentElement);
    }
    ReactDOM.render(<App {...dataFromDram} />, ldRoot);
  });
}

/**
 * If the document is still loading, add an event lister to "DOMContentLoaded" and trigger ldRoot rendering once the document is loaded and parsed. 
 * Otherwise, execute ldRoot rendering right away since the document has finished loading and been parsed
 * 
 */
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', renderLdRoot);
} else {
  renderLdRoot();
}
