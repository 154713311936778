import React from 'react';
import styles from './styles.module.css';

function Loader() {
  return (
    <div className={styles.container}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
      <div className={styles.loadingText}>Fetching card data...</div>
    </div>
  );
}

export default Loader;
