import React, { Component } from 'react';
import cardsLogo from '../../assets/cards-logo.png';
import styles from './styles.module.css';

type HoverContainerProps = {
  painterId: string,
  toggleModal: Function
};

class HoverContainer extends Component<HoverContainerProps> {
  render() {
    const { painterId, toggleModal } = this.props;
    return (
      <div className={styles.container} onClick={() => toggleModal()}>
        <img src={cardsLogo} className={styles.logoImage} alt="Cards Team Logo" />
        <div className={styles.cardNameText}>{painterId}</div>
      </div>
    );
  }
}

export default HoverContainer;