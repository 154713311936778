import sentryFetch from '@amzn/sentry-fetch';

const CARD_ONBOARDING_DB_URL = 'https://apigateway.cardonboarding-prod.us-east-1.devex.cards.a2z.com/v1/db';
const DEFAULT_CARD_ONBOARDING_DATA: CardOnboardingData = {
  emailId: 'N/A',
  teamName: 'N/A',
  teamId: 'N/A',
  cti: 'N/A:N/A:N/A',
  bindleId: 'N/A',
  pipelineId: 'N/A',
  awsAccountId: 'N/A',
  dashboard: {
    EU_PROD: 'N/A',
    FE_PROD: 'N/A',
    NA_PROD: 'N/A'
  }
};

interface CloudWatchDashboard {
  EU_PROD: string,
  FE_PROD: string,
  NA_PROD: string
}

interface DbCards {
  emailId: string,
  teamId: string,
  cti: string,
  bindleId: string,
  pipelineId: string,
  awsAccountId: string,
  dashboard?: Partial<CloudWatchDashboard>
}

interface DbTeams {
  emailId: string,
  teamName: string,
  teamId: string
}

export interface CardOnboardingData extends DbCards {
  teamName: string,
  dashboard: CloudWatchDashboard
}
/**
 * Return the object with fallback value 
 * when a property in given object is missing or the value is null or empty string 
 *
 * @param {*} obj
 * @param {*} fallback
 * @returns
 */
const merge = (obj: any, fallback: any) => {
  const ret: any = {};
  for (let key in fallback) {
    if (obj[key] === undefined || obj[key] === null) {
      ret[key] = fallback[key];
    } else if (typeof obj[key] === 'string') {
      ret[key] = obj[key] !== '' ? obj[key] : fallback[key];
    } else if (Array.isArray(obj[key])) {
      ret[key] = obj[key].length > 0 ? obj[key] : fallback[key];
    } else {
      ret[key] = merge(obj[key], fallback[key]);
    }
  }
  return ret;
}
/**
 * Get Card Onboarding data by card id
 * Fallback to default value if the request failed or value was missing
 *
 * @param {string} cardId
 * @returns {Promise<CardOnboardingData>}
 */
const getCardOnboardingDataByCardId = async (cardId: string): Promise<CardOnboardingData> => {
  const url = `${CARD_ONBOARDING_DB_URL}/card/${cardId}`;
  try {
    const response = await sentryFetch(url, {
      sentryOptions: { followMidwayStepUp: true, followMidwayStepUpOptions: { mode: 'modal' } },
      method: 'GET', mode: "cors", credentials: "include"
    });
    if (response.status !== 200) {
      return DEFAULT_CARD_ONBOARDING_DATA;
    }
    const data: DbCards = await response.json();
    return merge(data, DEFAULT_CARD_ONBOARDING_DATA);
  } catch (err) {
    console.error(`Fail to fetch data from ${url}: `, err.message);
    return DEFAULT_CARD_ONBOARDING_DATA;
  }
}

/**
 * Get team name by team id
 * Fallback to default value if the request failed or value was missing
 *
 * @param {string} teamId
 * @returns {Promise<String>}
 */
const getTeamNameByTeamId = async (teamId: string): Promise<string> => {
  if (teamId === 'N/A') {
    return 'N/A';
  }
  const url = `${CARD_ONBOARDING_DB_URL}/team/${teamId}`;
  try {
    const response = await sentryFetch(url, {
      sentryOptions: { followMidwayStepUp: true, followMidwayStepUpOptions: { mode: 'modal' } },
      method: 'GET', mode: "cors", credentials: "include"
    });
    if (response.status !== 200) {
      return 'N/A';
    }
    const data: DbTeams = await response.json();
    return data.teamName || 'N/A';
  } catch (err) {
    console.error(`Fail to fetch data from ${url}: `, err.message);
    return 'N/A';
  }
}
/**
 * Get complete data of a card from CardOnboarding Database
 *
 * @export
 * @param {string} cardId
 * @returns {Promise<CardOnboardingData>}
 */
export async function getCardDataFromDb(cardId?: string): Promise<CardOnboardingData> {
  if (!cardId) {
    return DEFAULT_CARD_ONBOARDING_DATA;
  }
  const cardOnboardingData = await getCardOnboardingDataByCardId(cardId);
  const teamName = await getTeamNameByTeamId(cardOnboardingData.teamId);

  return { ...cardOnboardingData, teamName };
}